import Basic from "./Basic";
import Default from "./Hero";
/**
 * @typedef {import("@prismicio/client").Content.HeroSlice} HeroSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<HeroSlice>} HeroProps
 * @param { HeroProps }
 */

const Hero = ({ slice: { primary, variation } }) => {
	if (variation == "default") return <Default primary={primary} />;
	if (variation == "basic") return <Basic primary={primary} />;
};

export default Hero;
