import { ScaledHeading } from "@/components";
import Styles from "./Basic.module.css";
import { PrismicRichText } from "@prismicio/react";
import { PrismicNextImage } from "@prismicio/next";

const Basic = ({ primary }) => {
	return (
		<div className={Styles.wrapper}>
			<div className={Styles.container} data-contain="true">
				<ScaledHeading
					heading={primary?.heading}
					dataContain="true"
					breakLines={false}
					margin={0}
					containerClass={Styles.heading}
				/>

				<span className={Styles.description}>
					<PrismicNextImage
						field={primary?.image}
						className={Styles.badgeImage}
					/>
					<PrismicRichText field={primary?.description} />
				</span>
			</div>
			<div className={Styles.backgroundContainer}>
				<PrismicNextImage field={primary?.background_image} />
				<div className={Styles.border} />
			</div>
		</div>
	);
};

export default Basic;
