import { PrismicRichText } from "@prismicio/react";
import { Button } from "@/components";
import Styles from "./Hero.module.css";

/**
 * @typedef {import("@prismicio/client").Content.HeroSlice} HeroSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<HeroSlice>} HeroProps
 * @param { HeroProps }
 */

const Default = ({ primary }) => {
  return (
    <section
      className={Styles.wrapper}
      style={{ minHeight: primary?.min_height + "vh" }}
    >
      <div className={Styles.container} data-contain="true">
        <div className={Styles.content}>
          <PrismicRichText field={primary.heading} />
          <span className={Styles.description}>
            <PrismicRichText field={primary.description} />
          </span>
          <div className={Styles.btnContainer}>
            {primary?.primary_cta_link && primary?.primary_cta_label && (
              <Button
                link={primary?.primary_cta_link}
                label={primary?.primary_cta_label}
              />
            )}
            {primary?.secondary_cta_link && primary?.secondary_cta_label && (
              <Button
                link={primary?.secondary_cta_link}
                label={primary?.secondary_cta_label}
                variant="secondary"
              />
            )}
          </div>
        </div>
      </div>
      <video
        src={primary?.background_video?.url}
        poster={primary.background_image.url}
        className={Styles.video}
        autoPlay
        playsInline
        muted
        loop
      ></video>
      <div
        className={Styles.overlay}
        style={{ opacity: `.${primary.overlay_opacity}` }}
      ></div>
    </section>
  );
};

export default Default;
